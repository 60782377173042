import React, { useEffect, useState } from "react";
import Marquee from "react-marquee-slider";
import styled from "styled-components";
import times from "lodash/times";
import { withSize } from "react-sizeme";
import { nanoid } from "nanoid";
import amLogo from "../assets/images/am.png"
import testimonials1 from "../assets/images/testimonials5.jpg";
import testimonials2 from "../assets/images/testimonials6.jpg";
import testimonials3 from "../assets/images/testimonials4.jpg";
import testimonials4 from "../assets/images/testimonials1.png";
import testimonials6 from "../assets/images/testimonials7.png";
import partHawk from "../assets/images/part-hawk.png";
import metavaders from "../assets/images/metavaders.png";

const Height = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.height ? props.height + "px" : "auto")};
`;

const Box = styled.div`
  padding: ${(props) => props.scale * 25}px;
`;

const Review = styled.div`
  width: ${(props) => props.scale * 700}px;
  display: flex;
  padding: ${(props) => props.scale * 25}px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.12);
`;

const Avatar = styled.div`
  border-radius: 50%;
  width: ${(props) => props.scale * 80}px;
  height: ${(props) => props.scale * 80}px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: ${(props) => props.scale * 15}px;
  img {
    max-width: 100%;
  }
`;

const Content = styled.div`
  p {
    margin: 0;
    color: #444;
    font-family: Helvetica, sans-serif;
    font-size: ${(props) => props.scale * 20}px;
    line-height: ${(props) => props.scale * 20}px;
    font-weight: 100;
    text-align: left;
  }
`;

const portraits = [
  "https://randomuser.me/api/portraits/women/68.jpg",
  "https://randomuser.me/api/portraits/men/75.jpg",
  "https://randomuser.me/api/portraits/lego/3.jpg",
  "https://randomuser.me/api/portraits/women/89.jpg",
  "https://randomuser.me/api/portraits/men/26.jpg",
];

const Reviews = ({ size, onStartPerformance, onEndPerformance }) => {

  const [key, setKey] = useState(nanoid());

  useEffect(() => {
    setKey(nanoid());
  }, [size, setKey]);

  let scale = 0.5;

  if (size && size.width > 800) {
    scale = 0.65;
  }

  if (size && size.width > 1100) {
    scale = 0.8;
  }

  if (size && size.width > 1400) {
    scale = 1;
  }

  return (
    <div className="w-full">
      <Height height={400}>
        <Marquee
          key={key}
          velocity={10}
          
          minScale={0.9}
          onInit={onStartPerformance}
          onFinish={onEndPerformance}
        >
        {/* PART 1 */}
            <Box key={`marquee-example-review-1`} scale={scale}>
              <Review scale={scale}>
                <Avatar scale={scale}>
                  <img src={amLogo} alt="Testimonial Image" />
                </Avatar>
                <Content scale={scale}>
                  <p>
                  "Again, Christos amazed us with his speed and reliability. Every project we trusted him with became a success. We recommend working with him and look forward to working with him again!"
                  - Matthias
                  </p>
                </Content>
              </Review>
            </Box>
             {/* PART 2 */}
             <Box key={`marquee-example-review-2`} scale={scale}>
              <Review scale={scale}>
                
                  <img src={testimonials1} alt="Testimonial Image" />
            
              
              </Review>
            </Box>
              {/* PART 3 */}
              <Box key={`marquee-example-review-3`} scale={scale}>
              <Review scale={scale}>
                
                  <img src={testimonials2} alt="Testimonial Image" />
            
              
              </Review>
            </Box>

          
            <Box key={`marquee-example-review-4`} scale={scale}>
              <Review scale={scale}>
                <Avatar scale={scale}>
                  <img src={partHawk} alt="Testimonial Image" />
                </Avatar>
                <Content scale={scale}>
                  <p>
                  "I was pleased with Christos's work. He was professional and communicated daily with me. He took the time to really understand the functionality of the application in order to deliver code that worked correctly and according to spec. We had a number of QA cycles internally to greenlight the app and Christos turned around bug fixes and minor enhancements quickly allowing us to move forward into user testing much faster than we anticipated. Overall, the project was simple but had a few tricky parts and I was impressed with Christos's ability to grasp the relevant pieces and come up with a solution. Definitely recommend"
                - Eugene
                  </p>
                </Content>
              </Review>
            </Box>

   {/* PART 4 */}
   <Box key={`marquee-example-review-3`} scale={scale}>
              <Review scale={scale}>
                
                  <img src={testimonials6} alt="Testimonial Image" />
            
              
              </Review>
            </Box>

          
      {/* PART 6 */}
      <Box key={`marquee-example-review-3`} scale={scale}>
              <Review scale={scale}>
                
                  <img src={testimonials4} alt="Testimonial Image" />
            
              
              </Review>
            </Box>

            {/* PART 5 */}
            <Box key={`marquee-example-review-3`} scale={scale}>
              <Review scale={scale}>
                
                  <img src={testimonials3} alt="Testimonial Image" />
            
              
              </Review>
            </Box>
        </Marquee>
      </Height>
    </div>
  );
};

// will end in a loop without React.memo
export default React.memo(withSize()(Reviews));