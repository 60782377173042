import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Reviews from "./reviews"
import Fade from 'react-reveal/Fade';
export default function TestimonialsSection() {


  return (
    // Not changing below margin to match other sections because of svg pattern spacing
    <section className="mb-24">
     
    <Fade top delays={700}>
      <h2 className="sm:text-lg sm:leading-snug text-center font-semibold tracking-wide uppercase text-teal-500 mb-3">
          Testimonials
        </h2>
        <p className="text-primary text-center text-3xl sm:text-5xl lg:text-5xl leading-none font-extrabold tracking-tight mb-8">
          100% Job Success on Upwork, several apps shipped and only 5 stars reviews.
        </p>
        </Fade>
        <div class="flex flex-wrap text-center justify-center">
     

      <Reviews />
    
      </div>

     
     
    </section>
  );
}
