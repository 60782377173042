import React, { useContext, useEffect } from 'react';
import avatar from '../assets/avatar.jpg';
import Visiualizer from './Three/Visualizer';
import { ThemeContext } from 'gatsby-plugin-theme-switcher';
const WelcomeSection = () => {
  const { switchTheme } = useContext(ThemeContext);



  useEffect(() => {
switchTheme('theme-dark')
  },[])

  return (
  <>
  <div className="home__canva">
  <Visiualizer/>
  </div>
  <section className="mb-24">
    <div className=" flex justify-between items-center relative">
      <div className=" w-full space-y-8 md:flex-row-reverse md:justify-between">
        <div className="text-left">
          <img
            src={avatar}
            alt="Christos Malamas"
            className="mt-8 mb-16 w-full ml-3 ml-auto h-full w-40  rounded-full ring-4 ring-offset-8 ring-offset-primary ring-blue-500 dark:ring-blue-400 select-none"
          />
          <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-teal-500 dark:text-teal-400 mb-3">
            Hi,
          </h2>
          <h1 className="text-primary text-3xl sm:text-5xl lg:text-5xl leading-none font-extrabold tracking-tight mb-4">
            I'm Christos Malamas.
          </h1>
          <p className="text-2xl text-secondary">
            I'm a  {" "}
            Senior Full-Stack Engineer, Machine Learning enthusiast and dog lover. Currently CTO of two startups and working with clients all over the world.
       
          </p>
        </div>
      </div>
    </div>
    <div className="absolute bottom-0 left-1/2 text-5xl leading-none text-coolGray-500 dark:text-coolGray-400 " />
  </section>
  </>
  )
}

export default WelcomeSection;
