import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import amLogo from "../assets/images/am.png"
import bajatuseguroLogo from "../assets/images/bajatuseguro.png";
import metavadersLogo from "../assets/images/metavaders.png";
import cioffilawfirm from "../assets/images/cioffilawfirm.png";
import book from "../assets/images/boook.png";
import mixerfi from "../assets/images/mixerfi.png"
import ntx from "../assets/images/ntx.png"
import partHawk from "../assets/images/part-hawk.png"
import grondas from "../assets/images/grondas.png"
const ImageScroller = () => {
  const [key, setKey] = useState(1);

  const scrolling = useSpring({
    from: { transform: "translate(60%,0)" },
    to: { transform: "translate(-60%,0)" },
    config: { duration: 25000 },
    reset: true,
    //reverse: key % 2 == 0,
    onRest: () => {
      setKey(key + 1);
    }
  });

  return (
    <div key={key} className="overflow-hidden clients__images p-2">
       <animated.div  className="flex items-center w-full justify-center text-center" style={scrolling}>

<a href="https://www.am.gmbh/" target="_blank">
       <img
        src={amLogo}
         alt="Cover image for playground section"
        //  layout="fill"
         className={'image mr-4' }
         style={{borderRadius: "20px"}}
        
         
        //  placeholder="blur"
        //  layout="responsive"
       />
  </a>
  <a href="https://bajatuseguro.com/" target="_blank">
       <img
        src={bajatuseguroLogo}
         alt="Cover image for playground section"
        //  layout="fill"
         className={'image mr-4' }
        
         
        //  placeholder="blur"
        //  layout="responsive"
       />
  </a>
  <a href="metavaders.io" target="_blank"> 
  <img
        src={metavadersLogo}
         alt="Cover image for playground section"
        //  layout="fill"
        className={'image mr-4 dark:bg-transparent bg-black dark:p-0 p-4 dark:rounded-0 rounded' }
        
         
        //  placeholder="blur"
        //  layout="responsive"
       />
  </a>
  <a href="https://cioffilawfirm.com/" target="_blank">
       <img
       style={{maxWidth: "200px"}}
        src={cioffilawfirm}
         alt="Cover image for playground section"
        //  layout="fill"
         className={'image mr-4 dark:bg-transparent bg-black dark:p-0 p-4 dark:rounded-0 rounded' }
        
         
        //  placeholder="blur"
        //  layout="responsive"
       />
  </a>
  <a  href="#">
       <img
        src={book}
         alt="Cover image for playground section"
        //  layout="fill"
         className={'image mr-4' }
        
      
         
        //  placeholder="blur"
        //  layout="responsive"
       />
  </a>
    
         <img
        src={mixerfi}
         alt="Cover image for playground section"
        //  layout="fill"
        className={'image mr-4' }
        style={{borderRadius: "20px"}}
        //  placeholder="blur"
        //  layout="responsive"
       />
  
<a href="">
  <img
        src={ntx}
         alt="Cover image for playground section"
        //  layout="fill"
        className={'image mr-4' }
         
        //  placeholder="blur"
        width={250}
        height={250}
        //  layout="responsive"
       />
  </a>
 <a>
  <img
        src={partHawk}
         alt="Cover image for playground section"
        //  layout="fill"
        className={'image mr-4' }
         
        //  placeholder="blur"
       
        //  layout="responsive"
       />
  </a>
  <a href="https://tour-greece.site/" target="_blank"> 
  <img
        src={grondas}
         alt="Cover image for playground section"
        //  layout="fill"
        className={'image mr-4' }
         
        
        //  placeholder="blur"
        //  layout="responsive"
       />
  </a>

  </animated.div>




 
    </div>
  );
};

export default ImageScroller;
