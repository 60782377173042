import React from 'react';
import Fade from "react-reveal/Fade"
const MusicSection = () => (
  <section>
    <Fade delays={700}>

   
    <h2 className="sm:text-lg sm:leading-snug text-center font-semibold tracking-wide uppercase text-rose mb-3">
      About me
    </h2>
    <p className="text-primary  text-center text-3xl sm:text-5xl lg:text-5xl leading-none font-extrabold tracking-tight mb-4">
      Coding without music ? <br /> I surely can't.
    </p>
    <p className="text-secondary prose-lg mb-6 text-center">
    The Nocturnes by Chopin are my favorite pieces of music. Not a single day goes by without listening to them. I perceive myself as a maestro and all the microservices, APIs, front-ends I build are my orchestra. Hence, listening to those masterpieces helps me focus and to remind myself to build something as extroardinary as what I'm listening. The only conflict I have is that I can't tell which opus is my favorite. It always comes down to Nocturne Op.27 No.2 and Nocturne Op. 48 No.1. Enjoy !
    </p>
    </Fade>
    <div>
      <iframe
        title="Coding Playlist"
        className="w-full bg-transparent overflow-hidden"
        allow="autoplay *; encrypted-media *;"
        frameBorder="0"
        height="450"
        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
        src="https://embed.music.apple.com/us/album/chopin-nocturnes-selection/3317320"
      />
    </div>
  </section>
);

export default MusicSection;
