import React from "react";
import { useSpringCarousel } from 'react-spring-carousel-js'

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import clientWork1 from "../assets/images/clientwork1.png";
import clientWork2 from "../assets/images/clientwork2.png";
import clientWork3 from "../assets/images/clientWork3.png";
import clientWork4 from "../assets/images/clientWork4.png";
import clientWork5 from "../assets/images/clientWork5.png"
import buddyLogo from "../assets/images/buddy-logo.png";
import amLogo from "../assets/images/am.png"
import metavader from "../assets/images/metavaders.png"
import cioffiLawFirm from "../assets/images/cioffilawfirm.png"
import { Link } from "gatsby";
// Philosophy about piece of code I write...

const Work = () => {
  const boxShadowDark = "blue 0px 0px 0px 2px inset, rbga(245, 245, 245,1) 10px -10px 0px -3px, rbga(31, 193, 27) 10px -10px, rbga(245, 245, 245,1) 20px -20px 0px -3px, rbga(255, 217, 19,1) 20px -20px, rbga(255, 255, 255,1) 30px -30px 0px -3px, rbga(255, 156, 85) 30px -30px, rbga(245, 245, 245,1) 40px -40px 0px -3px, rbga(245, 85, 85,1) 40px -40px;"

    const {
        carouselFragment,
        slideToPrevItem,
        slideToNextItem,
      } = useSpringCarousel({
        disableGestures: false,
        items: [
            {
                id: "CarouselItem-4",
                renderItem: <div class="carousel__container">
                <Link to="/case-study-metavaders" class="relative cursor-pointer w-full max-w-screen-sm py-4 lg:w-11/12 w-11/12 mx-auto mr-auto rounded-2xl ">
                  <img alt="Card" src={clientWork4} class="max-w-full rounded-lg img__float md:w-4/5 w-full"/>
                    </Link>
                    <div class="shadow"></div>
                <div class="relative w-full lg:-mt-40 max-w-screen-sm lg:11/12 w-11/12 p-4 ml-auto bg-blue-800 rounded-2xl z-50 boxMultiShadow">
                    <div class="flex flex-col text-white">
                 
                            {/* <div class="flex justify-between pl-2">
                            <img src={metavader} style={{maxWidth: "50px", borderRadius: "20px"}}/>
                                <i class="fas fa-quote-right text-2xl" />
                            </div> */}
                            <p class="text-white my-5 px-2">
                            Building a Dapp which consisted of creating smart contracts and interacting with the Ethereum Blockchain with Web3.
                            </p>
                            <div class="col-span-12 lg:col-span-8">
                            <div  class="inline-block rounded-full text-white 
                                bg-red-400 hover:bg-red-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                               React
                            </div>
                            <div  class="inline-block rounded-full text-white 
                                bg-blue-400 hover:bg-blue-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                              Web3
                            </div>
                         
                            <div  class="inline-block rounded-full text-white 
                                bg-yellow-400 hover:bg-yellow-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                              Javascript
                            </div>
                            <div  class="inline-block rounded-full text-white 
                                bg-green-400 hover:bg-green-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                                Blockchain
                            </div>
                          
                            
                        </div>
                        <Link to="/case-study-metavaders" className="mt-2">
              <button className="p-4 mt-2 items-center flex justify-center text-center md:w-1/2 w-full  mx-auto font-medium text-white transition duration-300 transform bg-black border-2 border-black cursor-pointer dark:bg-white dark:border-white dark:text-black rounded-xl hover:opacity-80">
              <span className="mr-2">Read more</span> <span><FaArrowRight /></span> 
              </button>
        </Link>
                        </div>
                    </div>
                   
                </div>,
              },
              {
                id: "CarouselItem-2",
                renderItem:  <div class="carousel__container">
               <Link to="/case-study-am" class="relative cursor-pointer w-full max-w-screen-sm py-4 lg:w-11/12 w-11/12 mx-auto mr-auto rounded-2xl ">
                  <img alt="Card" src={clientWork2} class="max-w-full rounded-lg  md:w-4/5 w-full"/>
                    </Link>
                <div class="relative w-full lg:-mt-40 max-w-screen-sm lg:11/12 w-11/12 p-4 ml-auto bg-blue-800 rounded-2xl z-50 boxMultiShadow">
                    <div class="flex flex-col text-white">
                 
                            {/* <div class="flex justify-between pl-2">
                            <img src={amLogo} style={{maxWidth: "50px", borderRadius: "20px"}}/>
                                <i class="fas fa-quote-right text-2xl" />
                            </div> */}
                            <p class="text-white my-5 px-2">
                            Creating a decision-tree based questionnaire to provide recommendation for investments opportunities.
                            </p>
                            <div class="col-span-12 lg:col-span-8">
                            <div  class="inline-block rounded-full text-white 
                                bg-red-400 hover:bg-red-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                                React / Node.JS
                            </div>
                            <div  class="inline-block rounded-full text-white 
                                bg-yellow-400 hover:bg-yellow-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                              AWS Lamda
                            </div>
                            <div  class="inline-block rounded-full text-white 
                                bg-green-400 hover:bg-green-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                                SaSS - Tailwind
                            </div>
                            <div  class="inline-block rounded-full text-white 
                                bg-blue-400 hover:bg-blue-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                              Javascript
                            </div>
                        </div>
                        </div>
                        <Link to="/case-study-am" className="mt-4">
              <button className="p-4 mt-2 items-center flex justify-center text-center md:w-1/2 w-full  mx-auto font-medium text-white transition duration-300 transform bg-black border-2 border-black cursor-pointer dark:bg-white dark:border-white dark:text-black rounded-xl hover:opacity-80">
              <span className="mr-2">Read more</span> <span><FaArrowRight /></span> 
              </button>
        </Link>
                    </div>
                </div>
                    ,
              },
          
       
          {
            id: "CarouselItem-5",
            renderItem: <div class="carousel__container">
             <Link to="/case-study-automation" class="relative cursor-pointer w-full max-w-screen-sm py-4 lg:w-11/12 w-11/12 mx-auto mr-auto rounded-2xl ">
              <img alt="Card" src={clientWork5} class="max-w-full rounded-lg md:w-4/5 w-full"/>
                </Link>
            <div class="relative w-full lg:-mt-40 max-w-screen-sm lg:11/12 w-11/12 p-4 ml-auto bg-blue-800 rounded-2xl z-50 boxMultiShadow">
                <div class="flex flex-col text-white">
             
                        <div class="flex justify-between pl-2">
                            <i class="fas fa-quote-right text-2xl" />
                        </div>
                        <p class="text-white my-5 px-2">
                        Building bots with Puppeteer which will automate login, creating new products, evaluate data and interacting with a custom backend for a marketplace app.                        </p>
                        <div class="col-span-12 lg:col-span-8">

                        <div  class="inline-block rounded-full text-white 
                            bg-blue-400 hover:bg-blue-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                          Puppeteer
                        </div>
                        
                        <div  class="inline-block rounded-full text-white 
                            bg-red-400 hover:bg-red-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                           Node.js
                        </div>
                        <div  class="inline-block rounded-full text-white 
                            bg-red-400 hover:bg-red-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                           MongoDB
                        </div>
                        <div  class="inline-block rounded-full text-white 
                            bg-yellow-400 hover:bg-yellow-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                          Typescript
                        </div>
                      
                    </div>
                    </div>
                    <Link to="/case-study-automation" className="mt-4">
              <button className="p-4 mt-2 items-center flex justify-center text-center md:w-1/2 w-full  mx-auto font-medium text-white transition duration-300 transform bg-black border-2 border-black cursor-pointer dark:bg-white dark:border-white dark:text-black rounded-xl hover:opacity-80">
              <span className="mr-2">Read more</span> <span><FaArrowRight /></span> 
              </button>
        </Link>
                </div>
            </div>,
          },
          {
            id: "CarouselItem-1",
            renderItem: 
           
                <div class="carousel__container">
		<Link to="/case-study-cioffilawfirm" class="cursor-pointer relative w-full max-w-screen-sm py-4 lg:w-11/12 w-11/12 mx-auto mr-auto rounded-2xl ">
			<img alt="Card" src={clientWork1} class="max-w-full rounded-lg  md:w-4/5 w-full"/>
        </Link>
		<div class="relative w-full lg:-mt-40 max-w-screen-sm lg:11/12 w-11/12 p-4 ml-auto bg-blue-800 rounded-2xl z-50 boxMultiShadow">
		    <div class="flex flex-col text-white">
				
                {/* <div class="flex justify-between pl-2">
                <img src={cioffiLawFirm}/>
                    <i class="fas fa-quote-right text-2xl" />
                </div> */}
                <p class="text-white my-5 px-2">
                 Redesigning and reinventing the brand of this succesful law firm based in NorthCarolina.
                </p>

                <div class="col-span-12 lg:col-span-8">
                        <div  class="inline-block rounded-full text-white 
                            bg-red-400 hover:bg-red-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                            Gatsby - GraphQL
                        </div>
                        <div  class="inline-block rounded-full text-white 
                            bg-yellow-400 hover:bg-yellow-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                            Netlify CMS
                        </div>
                        <div  class="inline-block rounded-full text-white 
                            bg-green-400 hover:bg-green-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                            SaSS - Tailwind
                        </div>
                        <div  class="inline-block rounded-full text-white 
                            bg-blue-400 hover:bg-blue-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                            Typescript
                        </div>
                    </div>
                
            </div>

            <Link to="/case-study-cioffilawfirm" className="mt-4">
              <button className="p-4 mt-2 items-center flex justify-center text-center md:w-1/2 w-full  mx-auto font-medium text-white transition duration-300 transform bg-black border-2 border-black cursor-pointer dark:bg-white dark:border-white dark:text-black rounded-xl hover:opacity-80">
              <span className="mr-2">Read more</span> <span><FaArrowRight /></span> 
              </button>
        </Link>
        </div>
       
    </div>
        
              ,
          },
          {
            id: "CarouselItem-3",
            renderItem: <div class="carousel__container">
            <div class="relative w-full max-w-screen-sm py-4 cursor-pointer lg:w-11/12 w-11/12 mx-auto mr-auto rounded-2xl ">
              <img alt="Card" src={clientWork3} class="max-w-full rounded-lg  md:w-4/5 w-full"/>
                </div>
            <div class="relative w-full lg:-mt-40 max-w-screen-sm lg:11/12 w-11/12 p-4 ml-auto bg-blue-800 rounded-2xl z-50 boxMultiShadow">
                <div class="flex flex-col text-white">
             
                        <div class="flex justify-between pl-2">
                        <img src={buddyLogo} style={{maxWidth: "50px", borderRadius: "20px"}}/>
                            <i class="fas fa-quote-right text-2xl" />
                        </div>
                        <p class="text-white my-5 px-2">
                         Creating a mobile app to provide all info in real-time regarding your favorite crypto for a client.
                        </p>
                        <div class="col-span-12 lg:col-span-8">
                        <div  class="inline-block rounded-full text-white 
                            bg-red-400 hover:bg-red-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                            React Native
                        </div>
                        <div class="inline-block rounded-full text-white 
                            bg-yellow-400 hover:bg-yellow-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                          Typescript
                        </div>
                        <div class="inline-block rounded-full text-white 
                            bg-green-400 hover:bg-green-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                            AWS Amplify
                        </div>
                        <div class="inline-block rounded-full text-white 
                            bg-blue-400 hover:bg-blue-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                          GraphQL
                        </div>
                    </div>
                    </div>
                </div>
            </div>,
          },
         
          
        ],
      });

    return (
      
        <div class="flex flex-wrap text-center justify-center relative">
      

        <section >
          <div className="flex justify-center items-center text-right btn__container">
          <button onClick={slideToPrevItem} className="relative mr-20 flex justify-between  items-center p-4 mt-4 mr-4 font-medium text-white transition duration-300 transform bg-black border-2 border-black cursor-pointer dark:bg-white dark:border-white dark:text-black rounded-xl hover:opacity-80">
    
        <div class="relative">
 <FaArrowLeft />
 
</div>
<p className="ml-4 text-white  dark:text-black ">Prev</p>
        </button> 
        <button onClick={slideToNextItem} className="relative flex justify-between border-primary flex items-center p-4 mt-4 text-black transition duration-300 transform border-2  cursor-pointer dark:border-white dark:text-white rounded-xl hover:opacity-70">
        <p className="mr-4 text-primary">Next</p>
        <div class="relative text-primary">
  <FaArrowRight />
</div>
        </button> 
          </div>
     
     <div className="container">
      {carouselFragment}
      </div>
    </section>
      </div>
    )
}

export default Work