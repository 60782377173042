/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const NewsletterSection = () => (
  <section>
 
  </section>
);

export default NewsletterSection;
