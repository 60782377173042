import React from 'react';
import Work from './work';
import Fade from "react-reveal/Fade"

const ProjectsSection = () => (
  <section className="mb-24">
    <Fade bottom delays={700}>
  
    <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-orange mb-3 text-center">
    Case Studies
    </h2>
    <div className="text-primary text-3xl sm:text-5xl lg:text-5xl leading-none font-extrabold tracking-tight mb-4 text-center">
      <p className="mb-2">Here's a few apps I have built for my clients.</p>
      {/* <p className="mb-8">Here's a few.</p> */}
    </div>
    </Fade>
  <Work />
  </section>
);

export default ProjectsSection;
