import React from 'react';
import NewsletterSection from '~components/newsletter';
import ImageScroller from './image-scroller';
import ReactReveal from 'react-reveal/Fade'



export default function BlogSection() {
 

  return (
    // Not changing below margin to match other sections because of svg pattern spacing
    <section className="mb-24">
     
    
      <h2 className="sm:text-lg sm:leading-snug text-center font-semibold tracking-wide uppercase text-indigo mb-3">
          Clients
        </h2>
        <p className="text-primary text-center text-3xl sm:text-5xl lg:text-5xl leading-none font-extrabold tracking-tight mb-8">
          Obsessed with quality.
        </p>

        <div class="flex flex-wrap text-center justify-center">
        <ReactReveal delays={700}>
        <div class="w-full  px-4" left>
          <p class="text-secondary  prose-lg">
          I have managed to build strong relations with my clients on complex and challenging projects. The secret for me, is building trust and clear communication from the start. I strongly believe in challenging my clients and providing my opinion when I feel like a feature or a new piece of code might not be directly correlated with their business value proposition. Anyone can write codes, the difference between going with an agency instead of me is that you communicate directly with the person responsible to write meticulously written, tested and clean code. Below are some of my clients and my work.
          </p>
        </div>
        </ReactReveal>
        

     
      <div className="w-full border-t border-primary mb-1 mt-7" />

        <ReactReveal className="flex" delays={700}>
        <ImageScroller />
        </ReactReveal>
        <div className="w-full border-t border-primary mb-1 mt-7" />
      </div>

     
      <div className="mt-12">
        <NewsletterSection />
      </div>
    </section>
  );
}
